import { useState, useEffect, useRef } from "react";
import SwiperCore, { Navigation, EffectFade } from "swiper";
import { PC, Mobile, useDeviceType } from "hooks/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";

// img
import IconPlus from "resources/image/icon/icon_plus.svg";
import LawyerImg01 from "resources/image/main/lawyer_img_01.png";
import LawyerImg02 from "resources/image/main/lawyer_img_02.png";
import LawyerImg03 from "resources/image/main/lawyer_img_03.png";
import LawyerImg04 from "resources/image/main/lawyer_img_04.png";
import LawyerImg05 from "resources/image/main/lawyer_img_05.png";
import LawyerImg06 from "resources/image/main/lawyer_img_06.png";
import MbLawyerImg01 from "resources/image/main/mb_lawyer_img_01.png";
import MbLawyerImg02 from "resources/image/main/mb_lawyer_img_02.png";
import MbLawyerImg03 from "resources/image/main/mb_lawyer_img_03.png";
import MbLawyerImg04 from "resources/image/main/mb_lawyer_img_04.png";
import MbLawyerImg05 from "resources/image/main/mb_lawyer_img_05.png";
import MbLawyerImg06 from "resources/image/main/mb_lawyer_img_06.png";
import BroadcastAniImg from "resources/image/main/broadcast_animation.png";
import MbBroadcastAniImg from "resources/image/main/mb_broadcast_animation.png";

function MainPressLawyer() {
  SwiperCore.use([Navigation, EffectFade]);
  const deviceType = useDeviceType();
  const [acitve, setAcitve] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setAcitve(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Styles.MainLawyer>
      <div
        ref={elementRef}
        className={acitve ? "active broad_banner" : "broad_banner"}
      >
        <img
          src={deviceType === "pc" ? BroadcastAniImg : MbBroadcastAniImg}
          className="broad_img"
          alt=""
        />
        <p>
          <span>성공사례의 중심</span>에는
          <br />
          언론이 먼저 찾는 <span>마약사건을 직접 수사</span>하던
        </p>
        <h2>
          <strong>배한진 변호사</strong>가<br /> 있습니다.
        </h2>
      </div>
      <Swiper
        modules={[Navigation, EffectFade]}
        fadeEffect={{ crossFade: true }}
        className="slider"
        slidesPerView={1}
        // loop={true}
        loop={false}
        // loopFillGroupWithBlank={true}
        loopFillGroupWithBlank={false}
        // speed={800}
        speed={0}
        // navigation={true}
        navigation={false}
      >
        <SwiperSlide>
          <div className="lawyer_slide">
            <div className="lawyer_inner">
              <p className="lawyer_tit">
                <strong>배한진</strong>검사출신 변호사
              </p>
              <button
                className="btn_more"
                onClick={() => {
                  window.location.href = `/intro/expert/detail/2248`;
                }}
              >
                자세히 보기
                <img src={IconPlus} alt="more view" />
              </button>
              <div className="lawyer_list">
                <ul>
                  <li>
                    인천지방검찰청 <b>마약</b> 특수부 검사
                    <br />(<b>마약</b>, 조직범죄, 공판)
                  </li>
                  <li>
                    대전지방검찰청 천안지청 검사(<b>마약</b>, 조직범죄, 공판)
                  </li>
                  <li>
                    대구지방검찰청 검사(<b>마약</b>, 조직범죄, 공판)
                  </li>
                  <li>
                    창원지방검찰청 거창지청 검사(<b>마약</b>, 조직범죄, 공판)
                  </li>
                  <li>
                    국정원, 미국 <b>마약단속국(DEA)</b>, 각국 마약청
                    <br />
                    공조수사 직접 지휘
                  </li>
                  <li>2017 대검찰청 선정 형사부 우수사례 검사</li>
                  <li>2020 대검찰청 선정 최우수 공판 검사</li>
                  <li>
                    2020 대검찰청 <b>마약·조직범죄과</b> 선정
                    <br />
                    우수업무사례 검사
                  </li>
                </ul>
                <ul>
                  <li>
                    해양경찰청 <b>마약범죄수사위원회</b> 자문위원
                  </li>
                  <li>인천경찰청 수사심의위원</li>
                  <li>서울경찰청 수사심의위원</li>
                  <li>경기남부경찰청 수사심의위원</li>
                  <li>경찰수사연수원 반부패 경찰수사관 대상 강의</li>
                  <li>
                    경기언론인클럽 주관
                    <br />
                    ‘한국의 <b>마약문제</b>와 치료재활정책’ 초청 강연
                  </li>
                  <li>
                    tvN {"<어쩌다어른>"} <b>“범죄와의 전쟁:마약편”</b> 출연
                  </li>
                  <li>
                    연합뉴스TV, 채널Ａ「뉴스라이브」 , MBC 「PD수첩」,
                    <br />
                    KBS 「9층시사국」 <b>마약 범죄</b> 관련 자문
                  </li>
                </ul>
              </div>
              <div className="tag_box">
                <span className="tag">
                  <b>마약</b> 수사 · 재판 경험 보유
                </span>
                <span className="tag">
                  <b>마약</b>관련 자문 수행
                </span>
              </div>
            </div>
            <PC>
              <img src={LawyerImg01} className="bnnr_img" alt="배한진 변호사" />
            </PC>
            <Mobile>
              <img
                src={MbLawyerImg01}
                className="bnnr_img"
                alt="배한진 변호사"
              />
            </Mobile>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="lawyer_slide">
            <div className="lawyer_inner">
              <p className="lawyer_tit">
                <strong>김영준</strong>부장검사 출신 변호사
              </p>
              <button
                className="btn_more"
                onClick={() => {
                  window.location.href = `/intro/expert/detail/2248`;
                }}
              >
                자세히 보기
                <img src={IconPlus} alt="more view" />
              </button>
              <div className="lawyer_list">
                <ul>
                  <li>
                    현직 검사들, 특사경, 경찰관을 교육한 법무연수원 <br /> 교수
                  </li>
                  <li>
                    대구지방검찰청 형사3 <b>부장검사</b> (강력, 안전사고, <br />
                    교통)
                  </li>
                  <li>
                    의정부지방검찰청 고양지청 <b>부장검사</b>
                  </li>
                  <li>서울중앙지방검찰청 검사</li>
                  <li>제주지방검찰청 부부장검사 (경찰 수사지휘 및 교수)</li>
                  <li>제주지방검찰청 검사 (경찰 수사지휘)</li>
                  <li>수원지방검찰청 검사</li>
                  <li>청주지방검찰청 충주지청 검사</li>
                </ul>
                <ul>
                  <li>전주지방검찰청 검사</li>
                  <li>의정부지방검찰청 고양지청 검사</li>
                  <li>
                    2024년 최신 <b>마약</b> 수사기법 연구
                  </li>
                </ul>
              </div>
              <div className="tag_box">
                <span className="tag">
                  <b>마약</b> 수사 · 재판 경험 보유
                </span>
                <span className="tag">부장 검사 출신</span>
              </div>
            </div>
            <PC>
              <img src={LawyerImg04} className="bnnr_img" alt="김영준 변호사" />
            </PC>
            <Mobile>
              <img
                src={MbLawyerImg04}
                className="bnnr_img"
                alt="김영준 변호사"
              />
            </Mobile>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lawyer_slide">
            <div className="lawyer_inner">
              <p className="lawyer_tit">
                <strong>김기룡</strong>부장검사 출신 변호사
              </p>
              <button
                className="btn_more"
                onClick={() => {
                  window.location.href = `/intro/expert/detail/2248`;
                }}
              >
                자세히 보기
                <img src={IconPlus} alt="more view" />
              </button>
              <div className="lawyer_list">
                <ul>
                  <li>
                    울산지방검찰청 <b>부부장검사</b>
                  </li>
                  <li>
                    의정부지방검찰청 고양지청 <b>부부장검사</b>
                  </li>
                  <li>
                    서울고등검찰청 <b>부부장검사</b>
                  </li>
                  <li>
                    전주지방검찰청 군산지청 <b>부장검사</b>
                  </li>
                  <li>수원지방검찰청 성남지청 검사</li>
                  <li>대전지방검찰청 검사</li>
                  <li>창원지방검찰청 검사</li>
                  <li>창원지방검찰청 마산지청 검사</li>
                </ul>
                <ul>
                  <li>수원지방검찰청 성남지청 검사</li>
                  <li>서울동부지방검찰청 검사</li>
                  <li>울산지방검찰청 검사</li>
                  <li>
                    2024년 최신 <b>마약</b> 수사기법 연구
                  </li>
                </ul>
              </div>
              <div className="tag_box">
                <span className="tag">
                  <b>마약</b> 수사 · 재판 경험 보유
                </span>
                <span className="tag">부장 검사 출신</span>
              </div>
            </div>
            <PC>
              <img src={LawyerImg05} className="bnnr_img" alt="김기룡 변호사" />
            </PC>
            <Mobile>
              <img
                src={MbLawyerImg05}
                className="bnnr_img"
                alt="김기룡 변호사"
              />
            </Mobile>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lawyer_slide">
            <div className="lawyer_inner">
              <p className="lawyer_tit">
                <strong>심강현</strong>검사출신 변호사
              </p>
              <button
                className="btn_more"
                onClick={() => {
                  window.location.href = `/intro/expert/detail/2249`;
                }}
              >
                자세히 보기
                <img src={IconPlus} alt="more view" />
              </button>
              <div className="lawyer_list">
                <ul>
                  <li>
                    수원지방검찰청 안산지청(<b>마약</b>, 조직범죄, 공판)
                  </li>
                  <li>서울서부지방검찰청(강력, 공판)</li>
                  <li>인천지방검찰청(성폭력. 공판)</li>
                  <li>전주지방검찰청 군산지청(조직범죄, 공판)</li>
                  <li>2012 가인법정변론대회 형사부분 가인상</li>
                  <li>2016 대검찰청 형사부 우수검사 선정</li>
                  <li>
                    2024 <b>최신 마약 수사기법</b> 연구
                  </li>
                </ul>
              </div>
              <div className="tag_box">
                <span className="tag">
                  <b>마약</b> 수사 · 재판 경험 보유
                </span>
                <span className="tag">
                  <b>마약</b>관련 자문 수행
                </span>
              </div>
            </div>
            <PC>
              <img src={LawyerImg02} className="bnnr_img" alt="심강현 변호사" />
            </PC>
            <Mobile>
              <img
                src={MbLawyerImg02}
                className="bnnr_img"
                alt="심강현 변호사"
              />
            </Mobile>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lawyer_slide">
            <div className="lawyer_inner">
              <p className="lawyer_tit">
                <strong>성인욱</strong>검사출신 변호사
              </p>
              <button
                className="btn_more"
                onClick={() => {
                  window.location.href = `/intro/expert/detail/2252`;
                }}
              >
                자세히 보기
                <img src={IconPlus} alt="more view" />
              </button>
              <div className="lawyer_list">
                <ul>
                  <li>
                    전주지방검찰청 군산지청 검사
                    <br />(<b>마약</b>, 사행퇴폐, 방실화)
                  </li>
                  <li>수원지방검찰청 안산지청 검사(마약, 강력, 의료)</li>
                  <li>인천지방검찰청 검사(마약 전담 합의재판부 공판)</li>
                  <li>전주지방검찰청(교통, 소년, 공판)</li>
                  <li>2018 광주고등검찰청 수사사무 우수사례 선정</li>
                  <li>2018 전주지검 군사지청장 선정 우수검사실</li>
                  <li>
                    2024 <b>최신 마약 수사기법</b> 연구
                  </li>
                </ul>
              </div>
              <div className="tag_box">
                <span className="tag">
                  <b>마약</b> 수사 · 재판 경험 보유
                </span>
                <span className="tag">
                  <b>마약</b>관련 자문 수행
                </span>
              </div>
            </div>
            <PC>
              <img src={LawyerImg03} className="bnnr_img" alt="성인욱 변호사" />
            </PC>
            <Mobile>
              <img
                src={MbLawyerImg03}
                className="bnnr_img"
                alt="성인욱 변호사"
              />
            </Mobile>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </Styles.MainLawyer>
  );
}

export default MainPressLawyer;
